
import Vue from 'vue'
// import FormCheckbox from '../forms/checkbox.vue'
import FormInput from '../forms/input.vue'
// import FormRadio from '../forms/radio.vue'
// import Abgabe from '../projekt/abgabe.vue'
import { addRow, getOptions, isNotNested, parseData, parseForm, removeRow } from '../../mixins/formData'

export default Vue.extend({
  name: 'LoginForm',

  data: () => {
    const formData: any = {}
    const subformData: any = {}
    return {
      email: '',
      errors: null,
      errorMsg: '',
      formName: 'user_signup',
      formObj: {},
      formData,
      loginField: {
        name: "login",
        label: "Login",
        type: "radio",
        options: [
          {
            "id": 1,
            "name": "Neuanmeldung",
            "value": "new"
          },
          {
            "id": 2,
            "name": "gespeicherte Anmeldung",
            "value": "old"
          },
          {
            "id": 3,
            "name": "Passwort vergessen",
            "value": "pass"
          },
        ],
      },
      loginType: 'old',
      pass: '',
      // pass_confirm: '',
      // privacy: false,
      privacyDescription: 'Ich erkläre mich mit den <a target="_blank" href="https://www.bim.bayern.de/datenschutz/" rel="noopener">Datenschutzbestimmungen</a> einverstanden.',
      subformData,
    }
  },

  components: {
    // FormCheckbox,
    FormInput,
    // FormRadio,
    // Abgabe,
  },

  computed: {
    authUser () {
      return this.$store.state.user
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    currentAward(): any {
      return this.$store.state.currentAward
    },
    currentPart () {
      return this.$route.params.part ||this.$store.state.currentAwardConf.forms.user_project_save.parts[0].key
    },
    didSignup () {
      return this.$store.state.didSignup
    },
    formParts () {
      if (!this.$store.state.userConf) return null
      return this.$store.state.userConf.forms[this.formName].parts
    },
    isBesichtigung(): boolean {
      return this.currentAward.phase === 'besichtigungstermineingabe'
    }
  },

  methods: {
    addRow (field: any, index: number) {
      const subformData = addRow(field, index, this.subformData)
      this.subformData = subformData
    },

    initSignup () {
      const { formData, subformData } = this.parseData(this.formParts, {}, this.formData)
      this.formData = Object.assign({}, this.formData, formData)
      this.subformData = Object.assign({}, this.subformData, subformData)
      this.errorMsg = ''
      this.errors = null
    },

    async sendLogin() {
      this.errorMsg = ''
      // validation
      if (!this.email || !this.pass) {
        return this.errorMsg = 'Die E-Mail und Passwort Felder müssen ausgefüllt sein.'
      }

      try {
        // login request
        const data = {
          username: this.email,
          password: this.pass,
        }
        const response = await fetch(this.baseUrl + 'login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        const res = await response.json()
        if (response.status >= 400) {
          throw new Error(response.statusText)
        } else {
          if (!res.error && res.user) {
            const { skipIntro, forceIntro } = this.currentAward
            if (!this.$store.state.appInfo) {
              this.$store.dispatch('getAppInfo')
            }
            this.$store.commit('setUser', res.user)
            if (this.didSignup && !skipIntro) {
              // on initial signup forward to "Kampagnendetails"
              // this.$router.push('/anmelden/info')
              this.$router.push('/anmelden/kontaktdaten')
            }
            else {
              if (forceIntro) {
                this.$store.commit('setInfoFromLogin', true)
                this.$router.push('/anmelden/info')
              }
              else {
                this.$router.push('/anmelden/index')
              }
            }
          } else {
            throw new Error(res.error)
          }
        }
      } catch (error) {
        // console.error('sendLogin error -> ', error.message)
        this.$store.commit('setAlert', {
          type: 'error',
          message: 'Fehler bei der Anmeldung, bitte überprüfen Sie Ihre Daten. Überprüfen Sie auch, ob Sie bereits einen Account besitzen.'
        })
        this.errorMsg = 'Die E-Mail und Passwort Felder müssen ausgefüllt sein.'
      }
    },

    async sendSignup() {
      this.errorMsg = ''

      // signup data
      let data = this.parseForm(this.formParts, this.formData)[this.currentPart]
      console.log('sendSignup -> data', data)

      // validation
      if (
        !data.email || !data.password  || !data.terms
      ) {
        let msg = 'Alle Pflichtfelder müssen ausgefüllt sein.'
        if (!data.terms) {
          msg += ' Sie müssen die Datenschutzbestimmungen akzeptieren.'
        }
        return this.errorMsg = msg
      }

      try {
        // signup request
        const response = await fetch(this.baseUrl + 'user/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        const res = await response.json()
        if (response.status >= 400) {
          if (res) {
            throw new Error(JSON.stringify(res))
          } else {
            throw new Error('error signing up')
          }
        } else {
          if (!res.error && res.email) {
            this.$store.commit('setUser', res)
            this.$store.commit('setAlert', {
              type: 'info',
              message: 'Registrierung erfolgreich, bitte melden Sie sich nun mit Ihren Daten an.'
            })
            this.loginType = 'old'
            this.$store.commit('setDidSignup', true)
            this.formData = {}
            this.initSignup()
          } else {
            throw new Error(res.error)
          }
        }
      } catch (error) {
        // console.log('sendSignup error -> ', error.message)
        this.$store.commit('setAlert', {
          type: 'error',
          message: 'Fehler bei der Registrierung, bitte überprüfen sie Ihre Daten.'
        })
        if ((error as any).message.indexOf('errorObject') >= 0) {
          const errors = JSON.parse((error as any).message)
          this.errors = errors.errorObject
        }
        this.errorMsg = 'Alle Pflichtfelder müssen ausgefüllt sein, bitte achten Sie auch auf eine korrekte E-Mail Adresse.'
      }
    },

    async submitLoginForm() {
      if (this.loginType === 'old') {
        this.sendLogin()
      } else if (this.loginType === 'new') {
        this.sendSignup()
      } else if (this.loginType === 'pass') {
        this.requestNewPassword()
      }

    },

    removeRow (field: any, index: number) {
      const { formData, subformData } = removeRow(field, index, this.formData, this.subformData)
      this.formData = formData
      this.subformData = subformData
    },

    async requestNewPassword() {
      this.errorMsg = ''
      if (!this.email) {
        return this.errorMsg = 'E-Mail muss ausgefüllt sein.'
      }

      try {
        const data = {
          email: this.email,
        }
        const response = await fetch(this.baseUrl + 'user/lostpassword', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        const res = await response.json()
        // for this feature we always give success message
        console.log('requestNewPassword success -> ', res);
        this.$store.commit('setAlert', {
          type: 'info',
          message: 'Sollte die Email Adresse bei uns registriert sein, werden Ihnen weitere Informationen zugesendet.'
        })
        this.loginType = 'old'
      } catch (error) {
        console.error('requestNewPassword error -> ', (error as any).message)
      }
    },

    getOptions,
    isNotNested,
    parseData,
    parseForm,
  },

  watch: {
    loginType () {
      this.errorMsg = ''
    },
  },

  mounted () {
    if (this.isBesichtigung) {
      this.loginType = 'old'
    }

    if (!this.currentAward) {
      return this.$router.push('/')
    }
    if (this.authUser) {
      return this.$router.push('/anmelden/index')
    }
    this.initSignup()
  },
})
